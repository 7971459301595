<template>
  <div>
    <h2>Página não encontrada.</h2>
    <h3><a href="/">Retornar à página inicial</a></h3>
  </div>
</template>

<script>
export default {
  name: '404Page',
  methods: {},
  created() {},
  computed: {},
}
</script>

<style src="./style.scss" lang="scss" scoped></style>
